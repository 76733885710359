@import url("https://fonts.googleapis.com/css?family=Playfair+Display|Poppins:300,400,500,600,700");

html,
body {
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  background-color: rgb(245, 245, 245);
  height: 100vh;
  overflow: auto;
  margin: 0;
}

#root {
  height: 100%;
  overflow: auto;
}

.container {
  margin: 0px auto 0px auto;
  padding: 50px 0px 50px 0px;
  max-width: 1200px;
  height: auto;
  min-height: calc(100vh - 200px);
}

.nav-container {
  /*margin: auto;*/
}

.nav-container svg {
  color: #fff;
}

a {
  text-decoration: none;
}
